<template>
    <div class="main_con">
        <!-- <el-card>
            <h2>人员培训</h2>
            <el-table :data="train_record" height="250" border id="finish_train" >
                <el-table-column prop="train_time" label="日期" width="180"></el-table-column>
                <el-table-column prop="topic" label="培训主题"></el-table-column>
                <el-table-column prop="peoples" label="人数"></el-table-column>
                <el-table-column label="操作" width="50">跳转</el-table-column>
            </el-table>
        </el-card>  -->
        <el-card id="table" v-loading="loading">
            <h2>{{ current_year }}年培训计划</h2>
            <table>
                <thead>
                    <tr>
                        <th>培训主题</th>
                        <th>类型</th>
                        <th>1月</th>
                        <th>2月</th>
                        <th>3月</th>
                        <th>4月</th>
                        <th>5月</th>
                        <th>6月</th>
                        <th>7月</th>
                        <th>8月</th>
                        <th>9月</th>
                        <th>10月</th>
                        <th>11月</th>
                        <th>12月</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in params_table" :key="index"
                        :style="{ backgroundColor: index % 2 !== 0 ? '#f7f7f7' : '' }">
                        <td>{{ item.topic }}</td>
                        <td>{{ item.type }}</td>
                        <td v-for="month, monthIndex in item.month" :key="monthIndex">
                            <!-- <el-button v-if="month != 0" type="info" plain 
                            @click="openDialog(item.topic, monthIndex)"
                            ref="myButton"
                            >{{
                                month }}</el-button> -->
                            <el-tag v-if="month != 0" @click="openDialog(item.topic, monthIndex)">{{ month
                                }}</el-tag>
                        </td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        
        <el-card>
            <h2 @click="redirectToPage">公司内部培训平台</h2>
            <div>
                <img src="../../../../static/personnel.jpg" class="responsive-image" @click="redirectToPage"
                    title="点击跳转至公司内部培训平台" />
            </div>
            <br>
        </el-card>

        <el-dialog v-if="current_topic != null" title="培训信息" :visible.sync="dialog" width="1300px">
            <el-card v-for="(item, index) in current_topic" :key="index" style="margin-bottom: 15px;">
                <el-descriptions title="培训信息" :column="3" border>
                    <el-descriptions-item label="培训主题">{{ item.topic }}</el-descriptions-item>
                    <el-descriptions-item label="工单号">{{ item.ticket_id }}</el-descriptions-item>
                    <el-descriptions-item label="完成状态" label-class-name="my-label"
                        :content-class-name="item.status === '已完成' ? 'success_content' : 'warning_content'">{{
                            item.status == "已完成" ? "已完成" : "未完成"
                        }}</el-descriptions-item>
                    <el-descriptions-item label="讲师">{{ item.teacher }}</el-descriptions-item>
                    <el-descriptions-item label="时间">{{ item.train_time }}</el-descriptions-item>
                    <el-descriptions-item label="类型">{{ item.type }}</el-descriptions-item>
                    <el-descriptions-item label="区域负责人">{{ item.manager }}</el-descriptions-item>
                    <el-descriptions-item label="人数">{{ item.peoples }}</el-descriptions-item>
                    <el-descriptions-item label="培训签到表">
                        <el-link :href=item.sign[0]>
                            签到表下载
                        </el-link>
                    </el-descriptions-item>
                    <el-descriptions-item label="培训材料">
                        <div v-for="(material, index) in item.material" :key="index">
                            <el-link :href=material>
                                {{ material_str(material) }}
                            </el-link>
                            <br>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item label="培训图片">
                        <el-image style="width: 100px; height: 100px" v-for="(img, index) in item.img" :key="index"
                            v-if="item.img != ''" :src="img" :preview-src-list="item.img">
                        </el-image>
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import { get_train_record } from '@/api/train';
import * as echarts from 'echarts';
export default {
    name: "PatrolSchedule",
    components: {},
    data() {
        let train_log = []
        let result;
        let temp = get_train_record()
            .then(response => {
                console.log(response)
                result = response.data.data
                temp = response.data.data
                console.log(temp)
                temp.forEach(element => {
                    // element.status=element.status==1?"已完成":"未完成"
                    if (element.train_time.includes("week")) {
                        result.splice(result.indexOf(element), 1)
                        this.getDateRangeFromWeek(element.train_time).forEach(ele => {
                            result.push({
                                "train_time": ele,
                                "manager": element.manager,
                                "type": element.type,
                                "topic": element.topic,
                                "teacher": element.teacher,
                                "status": element.status
                            })
                        })
                    }
                });
                result.sort((a, b) => new Date(a.train_time) - new Date(b.train_time));
                // let train_record = get_train_record()
                // train_record.forEach(element => {
                //     if (element.train_time.includes("week")) {
                //         let date = element.train_time.split("week")
                //         element.train_time = date[0] + `第${date[1]}周`
                //     }
                // })
                // train_record.sort((a, b) => new Date(a.train_time) - new Date(b.train_time));
            })
        return {
            value: new Date(),
            // train_record: train_record,
            drawer: false,
            params: result,
            params_table: [],
            current_year: 0,
            current_topic: null,
            drawer: false,
            current_dialog: null,
            loading: true,
            dialog: false,
            years: [],
        };
    },
    mounted() {
        this.get_train_record()
    },
    created() {
    },
    methods: {
        redirectToPage() {
            // 执行跳转操作
            window.open("https://www.italent.cn/portal/convoy/Learning?quark_s=6a3a865ddf497d01cad821abb1edd96defb36653f27d008d07a2b385244c433f&_qsrcapp=Italent&_qrt=html&_userSpecialType=1&xpuid=619726028&_menuId=21a3043f-430a-46ed-93e5-0dad234799af&_menuRootId=a1846e43-b58e-48a5-a9ef-307d7415d0a1&_pageType=legacy&_pageTitle=%E5%AD%A6%E4%B9%A0%E9%A6%96%E9%A1%B5&_restoreHref=https%3A%2F%2Fcloud.italent.cn%2FPageHome%2FIndex%3Fproduct%3DLearning%26keyName%3DNusion%26pageCode%3DLearningNewHomepage%26appCode%3DLearning%23%2FviewDynamic%3Fshadow_context%3D%257BappModel%253A%2522italent%2522%252Cuppid%253A%25221%2522%257D#widget/italent?iTalentFrameType=iframe&iTalentFrame=https%3A%2F%2Fcloud.italent.cn%2FPageHome%2FIndex%3Fproduct%3DLearning%26keyName%3DNusion%26pageCode%3DLearningNewHomepage%26appCode%3DLearning%23%2FviewDynamic%3Fshadow_context%3D%257BappModel%253A%2522italent%2522%252Cuppid%253A%2522%2522%257D", "_blank");
        },
        material_str(str) {
            if (str == '') {
                return
            }
            let name_1 = str.split("/")[7]
            let name = name_1.split("_")
            name = name.splice(0, name.length - 1)
            return name.join("_")
        },
        openDialog(topic, month) {
            let current_topic = this.params_table.findIndex(item => item.topic == topic)
            const buttons = this.$refs.monthButtons;
            this.current_topic = this.params_table[current_topic].record[month]
            this.dialog = true
        },
        changeYear(year) {

        },
        get_train_record(year = null) {
            if (year == null) {
                this.current_year = new Date().getFullYear();
            } else {
                this.current_year = year
            }
            let result = []
            get_train_record().then(response => {
                let temp = response.data.data
                temp.sort((a, b) => new Date(a.train_time) - new Date(b.train_time));
                temp.forEach(element => {
                    let year = element.train_time.split("-")[0]
                    if (this.years.findIndex((ele, index) => ele == year)) {
                        this.years.push(year)
                    }
                    if (year != this.current_year) countinue;
                    let index = result.findIndex(item => item.topic == element.topic)
                    let month = element.train_time.split("-")[1]
                    element.sign = element.sign.split(";")
                    element.material = element.material.split(";")
                    element.img = element.img.split(";")
                    if (index == -1) {
                        result.push({
                            "topic": element.topic,
                            "type": element.type,
                            "month": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            "record": Array.from({ length: 12 }, () => []), // 创建12个空数组
                        })
                        result[result.length - 1].month[month - 1] += 1
                        result[result.length - 1].record[month - 1].push(element);
                    } else {
                        result[index].month[month - 1] += 1
                        result[index].record[month - 1].push(element);
                    }
                })
                this.params_table = result
                this.loading = false
            }).catch(() => {
                // this.loading=false
            })
        },
        getDateRangeFromWeek(weekString) {
            // 解析输入的字符串
            const [yearMonth, week] = weekString.split('-week');
            const [year, month] = yearMonth.split('-').map(Number)
            const lastDayOfCurrentMonth = new Date(year, month, 0).getDate();
            console.log(lastDayOfCurrentMonth)
            // 创建存放7天的数组
            const dateRange = [];
            for (let i = ((week - 1) * 7) + 1; i <= week * 7 && i <= lastDayOfCurrentMonth; i++) {
                dateRange.push(yearMonth + `-${i < 10 ? `0${i}` : i}`); // 格式化为YYYY-MM-DD
            }
            //返回这周的所有时间
            return dateRange;
        },
        getStatusClass({ row, column, rowIndex, columnIndex }) {
            // 根据状态返回不同的类名
            if (columnIndex == 2) {
                return row.status === '已完成' ? 'success_content' : 'warning_content';
            }
        }
    },
};
</script>
<style scoped>
.main_con {
    text-align: center;
    padding: 16px;
}

.progressChart {
    width: 95%;
    height: 420px;
    border: 1px solid #aaa;
    margin: 0 auto;
    margin-top: 30px;
}

.success_content {
    background-color: #daffa2;
}

.graph {
    margin-top: 16px;
}

.warning_content {
    background-color: rgb(255 160 160 / 60%);
}

.right {
    display: flex;

    p {
        font-size: 14px;
        line-height: 14px;
        color: #333;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .line {
            display: inline-block;
            width: 100%;
            height: 3px;
            background: #1890ff;
            border-radius: 2px;
            margin-top: 8px;
        }
    }

    .line {
        display: inline-block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        border-radius: 2px;
        margin-top: 8px;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

td.left-align {
    text-align: left;
}

.responsive-image {
    max-width: 100%;
    /* 图片宽度不能超过父容器宽度 */
    height: auto;
    /* 图片高度按比例自动调整 */
  cursor: pointer;  /* 鼠标悬停时显示手指形状，提示用户可以点击 */
}

</style>