var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main_con" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { id: "table" }
        },
        [
          _c("h2", [_vm._v(_vm._s(_vm.current_year) + "年培训计划")]),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("培训主题")]),
                _c("th", [_vm._v("类型")]),
                _c("th", [_vm._v("1月")]),
                _c("th", [_vm._v("2月")]),
                _c("th", [_vm._v("3月")]),
                _c("th", [_vm._v("4月")]),
                _c("th", [_vm._v("5月")]),
                _c("th", [_vm._v("6月")]),
                _c("th", [_vm._v("7月")]),
                _c("th", [_vm._v("8月")]),
                _c("th", [_vm._v("9月")]),
                _c("th", [_vm._v("10月")]),
                _c("th", [_vm._v("11月")]),
                _c("th", [_vm._v("12月")])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.params_table, function(item, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    style: { backgroundColor: index % 2 !== 0 ? "#f7f7f7" : "" }
                  },
                  [
                    _c("td", [_vm._v(_vm._s(item.topic))]),
                    _c("td", [_vm._v(_vm._s(item.type))]),
                    _vm._l(item.month, function(month, monthIndex) {
                      return _c(
                        "td",
                        { key: monthIndex },
                        [
                          month != 0
                            ? _c(
                                "el-tag",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.openDialog(
                                        item.topic,
                                        monthIndex
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(month))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
          ])
        ]
      ),
      _c("el-card", [
        _c("h2", { on: { click: _vm.redirectToPage } }, [
          _vm._v("公司内部培训平台")
        ]),
        _c("div", [
          _c("img", {
            staticClass: "responsive-image",
            attrs: {
              src: require("../../../../static/personnel.jpg"),
              title: "点击跳转至公司内部培训平台"
            },
            on: { click: _vm.redirectToPage }
          })
        ]),
        _c("br")
      ]),
      _vm.current_topic != null
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "培训信息",
                visible: _vm.dialog,
                width: "1300px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialog = $event
                }
              }
            },
            _vm._l(_vm.current_topic, function(item, index) {
              return _c(
                "el-card",
                { key: index, staticStyle: { "margin-bottom": "15px" } },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "培训信息", column: 3, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "培训主题" } },
                        [_vm._v(_vm._s(item.topic))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "工单号" } },
                        [_vm._v(_vm._s(item.ticket_id))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: "完成状态",
                            "label-class-name": "my-label",
                            "content-class-name":
                              item.status === "已完成"
                                ? "success_content"
                                : "warning_content"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              item.status == "已完成" ? "已完成" : "未完成"
                            )
                          )
                        ]
                      ),
                      _c("el-descriptions-item", { attrs: { label: "讲师" } }, [
                        _vm._v(_vm._s(item.teacher))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "时间" } }, [
                        _vm._v(_vm._s(item.train_time))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "类型" } }, [
                        _vm._v(_vm._s(item.type))
                      ]),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "区域负责人" } },
                        [_vm._v(_vm._s(item.manager))]
                      ),
                      _c("el-descriptions-item", { attrs: { label: "人数" } }, [
                        _vm._v(_vm._s(item.peoples))
                      ]),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "培训签到表" } },
                        [
                          _c("el-link", { attrs: { href: item.sign[0] } }, [
                            _vm._v(" 签到表下载 ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "培训材料" } },
                        _vm._l(item.material, function(material, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("el-link", { attrs: { href: material } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.material_str(material)) + " "
                                )
                              ]),
                              _c("br")
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "培训图片" } },
                        _vm._l(item.img, function(img, index) {
                          return item.img != ""
                            ? _c("el-image", {
                                key: index,
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: img,
                                  "preview-src-list": item.img
                                }
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }